import { getClient } from "@/api/AxiosClient";
import { OrganizationApiResponse } from "@/api/types";
import { Logo } from "@/components/Logo";
import { LogoMinimized } from "@/components/LogoMinimized";
import { Button } from "@/components/ui/button";
import { Separator } from "@/components/ui/separator";
import { Toaster } from "@/components/ui/toaster";
import { useCredentialGetter } from "@/hooks/useCredentialGetter";
import { cn } from "@/util/utils";
import {
  UserButton,
  useAuth,
  useOrganizationList,
  useUser,
} from "@clerk/clerk-react";
import { SideNav } from "@cloud/routes/root/SideNav";
import {
  ExternalLinkIcon,
  Link2Icon,
  PinLeftIcon,
  PinRightIcon,
} from "@radix-ui/react-icons";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { usePostHog } from "posthog-js/react";
import { useEffect, useState } from "react";
import { Link, Outlet, useNavigate } from "react-router-dom";
import { BillingAlert } from "./BillingAlert";
import { Header } from "./Header";
import { Messenger } from "./Messenger";
import * as Sentry from "@sentry/react";
import { useSidebarStore } from "@/store/SidebarStore";

function RootLayout() {
  const postHog = usePostHog();
  const { collapsed, setCollapsed } = useSidebarStore();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [orgIsSet, setOrgIsSet] = useState(false);
  const { userId, orgId: vendorOrgId, isLoaded: isAuthLoaded } = useAuth();
  const user = useUser();
  const credentialGetter = useCredentialGetter();
  const { data: organizations } = useQuery<Array<OrganizationApiResponse>>({
    queryKey: ["organizations"],
    queryFn: async () => {
      const client = await getClient(credentialGetter);
      return await client
        .get("/organizations/")
        .then((response) => response.data.organizations);
    },
  });

  const organization = organizations?.[0];
  const skyvernOrgId = organization?.organization_id;
  const orgName = organization?.organization_name;
  const email = user.user?.primaryEmailAddress?.emailAddress;
  const userName = user.user?.fullName;
  const userCreatedAt = user.user?.createdAt;

  const {
    userMemberships,
    setActive,
    isLoaded: isOrganizationListLoaded,
  } = useOrganizationList({
    userMemberships: {
      infinite: true,
    },
  });

  useEffect(() => {
    if (isAuthLoaded && !userId) {
      navigate("/sign-in");
      return;
    }
    if (isAuthLoaded && skyvernOrgId) {
      const userProperties: Record<string, string> = {};
      if (email) {
        userProperties.email = email;
      }
      userProperties.organization_id = skyvernOrgId;
      postHog.identify(userId, userProperties);
      Sentry.setUser({ id: userId, email, organization_id: skyvernOrgId });
      if (vendorOrgId) {
        postHog.group("organization", skyvernOrgId, {
          organization_name: orgName,
          organization_id: skyvernOrgId,
        });
      }
    }
  }, [
    isAuthLoaded,
    userId,
    navigate,
    postHog,
    vendorOrgId,
    skyvernOrgId,
    email,
    orgName,
  ]);

  useEffect(() => {
    if (vendorOrgId) {
      if (!orgIsSet) {
        setOrgIsSet(true);
      }
      return;
    }
    if (!isOrganizationListLoaded || !isAuthLoaded) {
      return;
    }
    if (orgIsSet) {
      return;
    }
    if (userMemberships.data.length > 0) {
      setActive({
        organization: userMemberships.data[0]?.organization!.id,
      });
      setOrgIsSet(true);
    }
  }, [
    vendorOrgId,
    isOrganizationListLoaded,
    isAuthLoaded,
    userMemberships,
    orgIsSet,
    setActive,
  ]);

  useEffect(() => {
    queryClient.invalidateQueries();
  }, [vendorOrgId, queryClient]);

  return (
    <>
      <div className="h-full w-full">
        <aside
          className={cn("fixed h-screen min-h-screen border-r-2 px-6", {
            "w-64": !collapsed,
            "w-28": collapsed,
          })}
        >
          <div className="flex h-full flex-col">
            <Link to={window.location.origin}>
              <div className="flex h-24 items-center">
                {collapsed ? <LogoMinimized /> : <Logo />}
              </div>
            </Link>
            <SideNav collapsed={collapsed} />
            <Separator className="my-2" />
            <a
              href="https://docs.skyvern.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="mt-2 flex h-[3.25rem] items-center gap-4 rounded-2xl px-5 py-3 hover:bg-muted">
                <Link2Icon className="h-6 w-6" />
                {!collapsed && <span>API Docs</span>}
              </div>
            </a>
            <a
              href="https://meetings.hubspot.com/skyvern/demo"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="mt-2 flex h-[3.25rem] items-center gap-4 rounded-2xl bg-slate-200 px-5 py-3 font-bold text-slate-950 hover:bg-slate-100">
                <ExternalLinkIcon className="h-6 w-6" />
                {!collapsed && <span>Book a Demo</span>}
              </div>
            </a>
            <div
              className={cn("mt-auto flex min-h-16 items-center", {
                "justify-center": collapsed,
                "justify-between": !collapsed,
              })}
            >
              {!collapsed && (
                <UserButton
                  showName
                  appearance={{
                    elements: {
                      userButtonOuterIdentifier: "order-1",
                    },
                  }}
                />
              )}
              <Button
                size="icon"
                variant="ghost"
                onClick={() => {
                  setCollapsed(!collapsed);
                }}
              >
                {collapsed ? (
                  <PinRightIcon className="h-6 w-6" />
                ) : (
                  <PinLeftIcon className="h-6 w-6" />
                )}
              </Button>
            </div>
          </div>
        </aside>
        <Header />
        <main
          className={cn("pb-4 pl-64", {
            "pl-28": collapsed,
          })}
        >
          <BillingAlert />
          <Outlet />
        </main>
      </div>
      <Toaster />
      {isAuthLoaded && userId && email && userName && userCreatedAt && (
        <Messenger
          userId={userId}
          userName={userName}
          userEmail={email}
          userCreatedAt={user.user?.createdAt?.getTime() as number}
        />
      )}
    </>
  );
}

export { RootLayout };
